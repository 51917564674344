import React from "react"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import { Link } from "gatsby"
import { useStyletron } from "baseui"
import { Button } from "baseui/button"
import FlushedGrid from "../../../components/FlushedGrid"
import { Display, DisplayDescription } from "../../../components/typography"

const BannerSection = () => {
  const [css] = useStyletron()
  return (
    <>
      <Block
        paddingTop={["120px", "140px", "180px"]}
        paddingBottom={["20px", "20px", "100px", "160px"]}
        className={css({
          background: "rgb(13,87,163)",
          background:
            "linear-gradient(90deg,  rgba(32,164,207,1)  0%, rgba(13,87,163,1) 100%)",
        })}
      >
        <FlushedGrid gridGutters={[0, 48]} gridGaps={28}>
          <Cell span={[12, 12, 6]}>
            <Block
              display="flex"
              flexDirection="column"
              marginLeft="auto"
              marginRight={["auto", "auto", "auto", "0px"]}
              paddingRight={["40px", "40px", "40px", "0px"]}
              paddingLeft={["40px", "40px", "40px", "30px"]}
              maxWidth={["100%", "100%", "900px", "585px"]}
            >
              <Link to="https://www.verifyml.com">
                <div
                  style={{
                    display: "flex",
                    alignItems: 'center',
                    backgroundColor: "white",
                    fontWeight: 600,
                    maxWidth: "400px",
                    paddingTop: "8px",
                    marginBottom: "16px",
                    color: "black",
                    textDecoration: "none",
                  }}
                >
                  <div style={{height: "40px"}}>
                  <WinnerIcon />
                  </div>
                  <div
                    style={{
                      maxWidth: "350px",
                      marginBottom: "8px",
                      marginLeft: "4px"
                    }}
                  >
                    VerifyML - Winner of the Monetary Authority of Singapore
                    Global Veritas Challenge
                  </div>
                </div>
              </Link>
              <Display color="white">
                Fraud Detection Solutions Powered by Network Analytics
              </Display>
              <DisplayDescription color="white">
                Improve accuracy, transparency and actionability with our
                transaction monitoring and investigation solutions
              </DisplayDescription>
              <ButtonGroup />
            </Block>
          </Cell>
          <Cell span={[12, 12, 6]} align="center">
            <Block
              display={["none", "none", "none", "block"]}
              paddingRight="5%"
            >
              <img
                src="https://cylynx.imgix.net/uploads/cylynx-landing2.png"
                alt="Cylynx connect the dots"
                style={{ width: "100%" }}
              />
            </Block>
          </Cell>
        </FlushedGrid>
      </Block>
      <Block
        position="relative"
        top={["-100px", "-100px", "-220px", "-330px"]}
        display={["none", "none", "block"]}
      >
        <svg
          height="100%"
          width="100%"
          id="bg-svg"
          viewBox="0 0 1440 400"
          xmlns="http://www.w3.org/2000/svg"
          className="transition duration-300 ease-in-out delay-150"
        >
          <path
            d="M 0,400 C 0,400 0,200 0,200 C 112.89285714285714,180.78571428571428 225.78571428571428,161.57142857142858 350,179 C 474.2142857142857,196.42857142857142 609.7499999999999,250.5 738,247 C 866.2500000000001,243.5 987.2142857142858,182.42857142857142 1103,165 C 1218.7857142857142,147.57142857142858 1329.392857142857,173.78571428571428 1440,200 C 1440,200 1440,400 1440,400 Z"
            stroke="none"
            strokeWidth="0"
            fill="white"
            className="transition-all duration-300 ease-in-out delay-150"
          ></path>
        </svg>
      </Block>
    </>
  )
}

// Use black and white over here to prevent color shift from the transfer at top level
const ButtonGroup = () => (
  <Block display={["block", "flex"]} marginTop="32px">
    <Link to="/blocklynx">
      <Button
        overrides={{
          BaseButton: {
            style: {
              width: "220px",
            },
          },
        }}
      >
        Txn Monitoring
      </Button>
    </Link>
    <Link to="/motif">
      <Button
        kind="secondary"
        overrides={{
          BaseButton: {
            style: {
              color: "black",
              width: "220px",
              maxWidth: "220px",
              backgroundColor: "white",
              ":hover": {
                backgroundColor: "white",
              },
            },
          },
        }}
      >
        Investigations
      </Button>
    </Link>
  </Block>
)

function WinnerIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="40" viewBox="0 0 64 64">
      <g data-name="18-Winner">
        <path
          fill="#ff9102"
          d="M41 40.88L36.5 45.49 37.56 52 32 48.93 26.44 52 27.5 45.49 23 40.88 29.22 39.93 32 34 34.78 39.93 41 40.88z"
        ></path>
        <path
          fill="#d6e0eb"
          d="M29.5 14.27L32 11.38 41 1 46 1 33 16 28 16 29.5 14.27z"
        ></path>
        <path fill="#d6e0eb" d="M23 1L32 11.38 29.5 14.27 18 1 23 1z"></path>
        <path
          fill="#774e9d"
          d="M29.5 14.27L28 16 26 16 13 1 18 1 29.5 14.27z"
        ></path>
        <path fill="#9c59a9" d="M51 1L38 16 33 16 46 1 51 1z"></path>
        <path
          fill="#ffab02"
          d="M36 25.43a18.82 18.82 0 11-4-.43 19 19 0 014 .43zM47 44a15 15 0 10-15 15 15.005 15.005 0 0015-15z"
        ></path>
        <path
          fill="#ffda4d"
          d="M32 29a15 15 0 11-15 15 15.005 15.005 0 0115-15zm4.5 16.49l4.5-4.61-6.22-.95L32 34l-2.78 5.93-6.22.95 4.5 4.61L26.44 52 32 48.93 37.56 52z"
        ></path>
        <path fill="none" d="M60 40.88l.047.048v-.055z"></path>
        <path
          fill="#ff9102"
          d="M38 16L38 20 36 20 28 20 26 20 26 16 28 16 33 16 38 16z"
        ></path>
        <path fill="#fe4f46" d="M36 20v5.43a18.82 18.82 0 00-8 0V20z"></path>
        <path
          fill="#ffc400"
          d="M27.989 51.145L26.44 52l.338-2.074a14.915 14.915 0 01-3.731-9L23 40.88l.044-.007C23.027 40.583 23 40.3 23 40a14.926 14.926 0 013.941-10.106 14.989 14.989 0 1016.118 24.212 14.874 14.874 0 01-15.07-2.961z"
        ></path>
        <path
          fill="#ff9102"
          d="M32 27a17 17 0 1017 17 17 17 0 00-17-17zm0 32a15 15 0 1115-15 15.005 15.005 0 01-15 15z"
        ></path>
        <path fill="#fa7a31" d="M26 16v4h12v-2h-7a2 2 0 01-2-2h-3z"></path>
        <path
          fill="#c1cfe8"
          d="M29.5 8.5l-2.03 2.03a1.975 1.975 0 01-.8.48l2.83 3.26 2.5-2.89z"
        ></path>
        <path
          fill="#593a75"
          d="M24.588 10.47L15.846 1H13l13 15h2l1.5-1.73-2.827-3.262a1.991 1.991 0 01-2.085-.538z"
        ></path>
        <path
          fill="#e84840"
          d="M28 20v5.43a19.07 19.07 0 012-.322V23a1 1 0 011-1h5v-2z"
        ></path>
        <path d="M41.948 40.558a1 1 0 00-.8-.671l-5.691-.87-2.555-5.442a1 1 0 00-1.81 0l-2.552 5.442-5.691.87a1 1 0 00-.565 1.686l4.148 4.251-.982 6.015a1 1 0 001.471 1.036L32 50.067l5.079 2.808a.988.988 0 00.483.125 1 1 0 00.988-1.161l-.982-6.015 4.148-4.251a1 1 0 00.232-1.015zm-6.164 4.231a1 1 0 00-.271.859l.731 4.48-3.761-2.078a1 1 0 00-.966 0l-3.761 2.078.731-4.48a1 1 0 00-.271-.859l-3.141-3.22 4.3-.656a1 1 0 00.754-.563l1.876-4 1.876 4a1 1 0 00.754.563l4.295.656z"></path>
        <path d="M37 24.658V21h1a1 1 0 001-1v-3.627L51.756 1.655A1 1 0 0051 0H41a1 1 0 00-.756.345L29.5 12.742 20.19 2h2.353l6.635 7.655 1.511-1.31-6.933-8A1 1 0 0023 0H13a1 1 0 00-.756 1.655L25 16.373V20a1 1 0 001 1h1v3.658a20 20 0 1010 0zM37 19H27v-2h10zm.543-4H35.19L46.457 2h2.353zm3.914-13h2.353L32.543 15H30.19zM17.543 2l10.634 12.269-.634.731h-1.086L15.19 2zM29 21h6v3.25a18.125 18.125 0 00-6 0zm3 41a18 18 0 1118-18 18.021 18.021 0 01-18 18z"></path>
        <path d="M45.877 42.132l1.982-.264a15.994 15.994 0 00-.434-2.135l-1.928.534a13.883 13.883 0 01.38 1.865z"></path>
        <path d="M46 44a13.986 13.986 0 11-1.164-5.6l1.832-.8A16.007 16.007 0 1048 44z"></path>
      </g>
    </svg>
  )
}

export default BannerSection
