import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Button } from "baseui/button"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import Fade from "react-reveal/Fade"
import Img from "gatsby-image"
import Image from "../../components/Image"
import {
  Title,
  SubTitle,
  Description,
  ItemTitle,
  ItemDescription,
} from "../../components/typography"
import FlushedGrid from "../../components/FlushedGrid"
import Container from "../../components/UI/Container"

const DiscoverTransactionMonitoring = () => {
  const Data = useStaticQuery(graphql`
    query {
      cryptoJson {
        DISCOVERTM_FEATURE {
          title
          description
          icon {
            publicURL
          }
        }
      }
      imgixImage(url: "/uploads/blocklynx-screen.png") {
        fluid(maxWidth: 800) {
          ...GatsbyImgixFluid
        }
      }
    }
  `)

  return (
    <Block
      paddingTop="100px"
      paddingBottom="100px"
      id="discover-transaction-monitoring"
    >
      <Container>
        <FlushedGrid gridGutters={[0, 56]} gridGaps={48}>
          <Cell order={[2, 2, 1]} span={[12, 6]}>
            <Block
              display="flex"
              flexDirection="column"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <SubTitle>Discover Pre-Transaction Monitoring</SubTitle>
              <Title>End-to-End Compliance Trails</Title>
              <Description paddingBottom="40px">
                Move beyond identity verification - prioritise risk cases and
                improving screening efficiency
              </Description>
              <FlushedGrid gridGaps={[18, 32]}>
                {Data.cryptoJson.DISCOVERTM_FEATURE.map(item => (
                  <Cell key={`feature-${item.title}`} span={6}>
                    <Fade up>
                      <Block display="flex">
                        <Image
                          src={item.icon.publicURL}
                          alt={item.title}
                          style={{
                            width: "50px",
                            height: "50px",
                            marginBottom: "20px",
                            borderWidth: "2px",
                            borderColor: "rgb(233, 227, 254)",
                            borderRadius: "10px",
                            backgroundColor: "rgb(243, 242, 250)",
                            padding: "8px 10px",
                          }}
                        />
                        <Block marginLeft="15px">
                          <ItemTitle>{item.title}</ItemTitle>
                          <ItemDescription>{item.description}</ItemDescription>
                        </Block>
                      </Block>
                    </Fade>
                  </Cell>
                ))}
              </FlushedGrid>
              <Link to="/transaction-monitoring">
                <Button>Features and Capabilities</Button>
              </Link>
            </Block>
          </Cell>
          <Cell order={[1, 1, 2]} span={[12, 6]}>
            <Block
              display="flex"
              alignItems="center"
              height="100%"
              maxWidth="600px"
              marginLeft={["18px", "10%", "20%", 0]}
            >
              <Img
                fluid={{ ...Data.imgixImage.fluid }}
                style={{ width: "100%" }}
                alt="Blocklynx Image"
              />
            </Block>
          </Cell>
        </FlushedGrid>
      </Container>
    </Block>
  )
}

export default DiscoverTransactionMonitoring
