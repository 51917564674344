import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import Fade from "react-reveal/Fade"
import Text from "../../components/Text"
import Heading from "../../components/Heading"
import { PostCard } from "../../components/BlogComponents"
import FlushedGrid from "../../components/FlushedGrid"
import Container from "../../components/UI/Container"

const LatestPosts = ({ data, title, sectionSubTitle }) => {
  const { edges: posts } = data.allMdx
  return (
    <Block paddingTop="50px" paddingBottom="100px" id="news">
      <Container>
        <Text content="From the newsroom" {...sectionSubTitle} />
        <Heading content="Thoughts, Stories, Analysis" {...title} />
        <Block marginLeft={[0, "5%", "20%", 0]} marginRight={[0, "5%", 0, 0]}>
          <Fade up>
            <FlushedGrid>
              {posts &&
                posts.map(({ node }, i) => (
                  <Cell key={node.id} span={6}>
                    <PostCard post={node} num={i} isHome={false} />
                  </Cell>
                ))}
            </FlushedGrid>
          </Fade>
        </Block>
      </Container>
    </Block>
  )
}

// FeatureSection default style
LatestPosts.defaultProps = {
  // sub section default style
  sectionSubTitle: {
    as: "span",
    display: "block",
    textAlign: "center",
    fontSize: ["16px", "16px", "18px", "20px", "20px"],
    fontWeight: "600",
    lineHeight: "27px",
    color: "#525f7f",
  },
  title: {
    fontSize: ["24px", "26px", "30px", "36px", "40px"],
    lineHeight: ["30px", "32px", "40px", "50px", "55px"],
    letterSpacing: "-0.010em",
    mb: "20px",
    textAlign: "center",
  },
}

export default () => (
  <StaticQuery
    query={graphql`
      query LatestPosts {
        site {
          siteMetadata {
            title
          }
        }
        allMdx(
          limit: 2
          sort: { fields: [frontmatter___date], order: DESC }
          filter: {
            frontmatter: {
              template: { eq: "blog-post" }
              publish: { eq: true }
            }
          }
        ) {
          totalCount
          edges {
            node {
              id
              body
              timeToRead
              fields {
                slug
              }
              frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
                author
                featured
                tags
                hero_image
              }
              imgixImage {
                fluid(maxWidth: 600) {
                  ...GatsbyImgixFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => <LatestPosts data={data} />}
  />
)
