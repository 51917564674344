import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from "react-reveal/Fade"
import { Cell } from "baseui/layout-grid"
import { Block } from "baseui/block"
import FlushedGrid from "../../components/FlushedGrid"
import { SubTitle } from "../../components/typography"
import Container from "../../components/UI/Container"

const Testimonial = () => {
  const Data = useStaticQuery(graphql`
    query {
      logo1: imgixImage(url: "/uploads/g20-techsprint-logo.png") {
        fluid(maxWidth: 300 imgixParams:{monochrome:"8E9CA7"}) {
          ...GatsbyImgixFluid
        }
      }
      logo2: imgixImage(url: "/uploads/ice71-logo.png") {
        fluid(maxWidth: 300 imgixParams:{monochrome:"8E9CA7"}) {
          ...GatsbyImgixFluid
        }
      }
      logo3: imgixImage(url: "/uploads/cna-default-image.webp") {
        fluid(maxWidth: 300 imgixParams:{monochrome:"8E9CA7"}) {
          ...GatsbyImgixFluid
        }
      }
      logo4: imgixImage(url: "/uploads/regulations-asia-logo.png") {
        fluid(maxWidth: 300 imgixParams:{monochrome:"8E9CA7"}) {
          ...GatsbyImgixFluid
        }
      }
    }
  `)

  return (
    <Block
      id="testimonial_section"
      marginTop={["50px", "50px", "-300px", "-350px"]}
      marginBottom={["50px", "50px", "100px"]}
    >
      <Container>
        <Block display="flex" alignItems="center" flexDirection="column" marginBottom="24px">
          <SubTitle> As Featured In</SubTitle>
        </Block>
        <Block marginRight="20px" marginLeft="20px">
        <Fade up>
          <Block display="flex" alignItems="center" justifyContent="center" flexDirection={["column", "column", "row", "row"]}>
            <Block display="flex" width={["100%", "100%", "50%", "50%"]} alignItems="center" justifyContent="center">
            <Block width="50%">
              <Img
                fluid={{ ...Data.logo1.fluid }}
                style={{ width: "100%" }}
                alt="g20 techsprint"
              />
            </Block>
            <Block width="50%">
              <Img
                fluid={{ ...Data.logo2.fluid }}
                style={{ width: "100%" }}
                alt="ice71 singtel nus enterprise"
              />
            </Block>
            </Block>
            <Block display="flex"  width={["100%", "100%", "50%", "50%"]} alignItems="center" justifyContent="center">
            <Block width="50%"> 
              <Img
                fluid={{ ...Data.logo3.fluid }}
                style={{ width: "100%" }}
                alt="channel news asia"
              />
            </Block>
            <Block width="50%">
              <Img
                fluid={{ ...Data.logo4.fluid }}
                style={{ width: "100%" }}
                alt="regulations asia one to watch"
              />
            </Block>
          </Block>
          </Block>
          </Fade>
        </Block>
      </Container>
    </Block>
  )
}
export default Testimonial
