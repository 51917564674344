import React from "react"

import Banner from "../containers/MainLanding/BannerSection"
import Testimonial from "../containers/MainLanding/Testimonial"
import UseCases from "../containers/MainLanding/UseCases"
import DiscoverTransactionMonitoring from "../containers/MainLanding/DiscoverTransactionMonitoring"
import DiscoverMotif from "../containers/MainLanding/DiscoverMotif"
import LatestPosts from "../containers/LatestPosts"
import { Newsletter } from "../containers/Newsletter"
import { SEOPage } from "../components/SEO"
import Layout from "../components/layout"

export default () => {
  return (
    <Layout>
      <SEOPage title="Fraud Detection Solutions Powered by Network Analytics" />
      <Banner />
      <Testimonial />
      <DiscoverMotif />
      <DiscoverTransactionMonitoring />
      <LatestPosts />
      <Newsletter />
    </Layout>
  )
}
